import * as yup from 'yup';

const { Recipient } = require('./util');
const { PaymentMethods } = require('./consts');

const validators = require('./validators');

// ^\+358: Make sure phone number starts with country code, this is made sure by the util.formatPhoneNumber function
// [0-9]{5,12}$: Matches between 5 and 12 digits after country code
const phoneRegex = /^\+358[0-9]{5,12}$/;

export const ValidationError = {
  REQUIRED_FIELD: 'Pakollinen kenttä',
  INVALID_FIELD: 'Tarkista kenttä',
  INVALID_FIELD_CONTACT_SUPPORT: 'Tarkista kenttä. Ota tarvittaessa yhteyttä asiakaspalveluun',
  TERMS_NOT_ACCEPTED: 'Sinun on hyväksyttävä tilausehdot jatkaaksesi',
  INVALID_COMPANY_ID: 'Esimerkki-y-tunnus 1234567-1',
  INVALID_STARTING_DATE: 'Anna päivämäärä muodossa VVVV-KK-PV',
  STARTING_DATE_IN_THE_PAST: 'Annettu päivämäärä on jo mennyt',
  AT_LEAST_ONE_FIELD_IS_REQUIRED: 'Täytä vähintään yksi kenttä',
};

const subscriberDetails = (emailRequired, phoneRequired, requireStudentNumber) =>
  yup
    .object()
    .shape({
      payerFirstName: yup.string().required(ValidationError.REQUIRED_FIELD),
      payerLastName: yup.string().required(ValidationError.REQUIRED_FIELD),
      payerStreetAddress: yup.string().required(ValidationError.REQUIRED_FIELD),
      payerZipCode: yup
        .string()
        .required(ValidationError.REQUIRED_FIELD)
        .test(
          'length',
          ValidationError.INVALID_FIELD,
          (val) => val.length === 5,
        )
        .matches(/^[0-9]+$/, ValidationError.INVALID_FIELD),
      payerCity: yup.string().required(ValidationError.REQUIRED_FIELD),
      payerPhoneNumber: phoneRequired
        ? yup
          .string()
          .matches(phoneRegex, {
            message: ValidationError.INVALID_FIELD,
            excludeEmptyString: true,
          })
          .required(ValidationError.REQUIRED_FIELD)
        : yup
          .string()
          .matches(phoneRegex, {
            message: ValidationError.INVALID_FIELD,
            excludeEmptyString: true,
          }),
      payerBirthday: yup
        .string()
        .matches(/^[0-9]+$/, {
          message: ValidationError.INVALID_FIELD,
          excludeEmptyString: true,
        }),
      payerEmail: emailRequired
        ? yup.string().required(ValidationError.REQUIRED_FIELD)
        : yup.string(),
      payerStudentNumber: requireStudentNumber
        ? yup
          .string()
          .required(ValidationError.REQUIRED_FIELD)
          .matches(/^[0-9]+$/, ValidationError.INVALID_FIELD_CONTACT_SUPPORT)
        : yup.string().notRequired(),
    })
    .required();

const recipientDetails = () =>
  yup
    .object()
    .shape({
      recipientFirstName: yup.string().required(ValidationError.REQUIRED_FIELD),
      recipientLastName: yup.string().required(ValidationError.REQUIRED_FIELD),
      recipientStreetAddress: yup
        .string()
        .required(ValidationError.REQUIRED_FIELD),
      recipientZipCode: yup
        .string()
        .required(ValidationError.REQUIRED_FIELD)
        .test(
          'length',
          ValidationError.INVALID_FIELD,
          (val) => val.length === 5,
        )
        .matches(/^[0-9]+$/, ValidationError.INVALID_FIELD),
      recipientCity: yup.string().required(ValidationError.REQUIRED_FIELD),
      recipientPhoneNumber: yup
        .string()
        .matches(phoneRegex, {
          message: ValidationError.INVALID_FIELD,
          excludeEmptyString: true,
        }),
    })
    .required();

const companyDetails = () =>
  yup
    .object()
    .shape({
      payerLastName: yup.string().required(ValidationError.REQUIRED_FIELD),
      payerCompanyId: yup
        .string()
        .matches(/^[0-9]{7}-[0-9]{1}$/, {
          message: ValidationError.INVALID_COMPANY_ID,
          excludeEmptyString: true,
        }),
      payerZipCode: yup
        .string()
        .matches(/^[0-9]+$/, {
          message: ValidationError.INVALID_FIELD,
          excludeEmptyString: true,
        }),
      payerPhoneNumber: yup
        .string()
        .matches(phoneRegex, {
          message: ValidationError.INVALID_FIELD,
          excludeEmptyString: true,
        }),
      payerEmail: yup.string().required(ValidationError.REQUIRED_FIELD),
    })
    .required();

export const ORDER_FORM_SCHEMA = yup
  .object()
  .shape({
    subscriberDetails: yup
      .object()
      .when(['recipient', 'requireSubscriberInfo', 'paymentMethod', 'requireStudentNumber'], {
        is: (recipient, requireSubscriberInfo, paymentMethod, requireStudentNumber) =>
          [Recipient.TO_FRIEND, Recipient.TO_SELF].includes(recipient)
          && !!requireSubscriberInfo
          && (paymentMethod === PaymentMethods.INVOICING_CALL || paymentMethod === PaymentMethods.MOBILEPAY)
          && requireStudentNumber,
        then: subscriberDetails(true, true, true),
      })
      .when(['recipient', 'requireSubscriberInfo', 'paymentMethod', 'requireStudentNumber'], {
        is: (recipient, requireSubscriberInfo, paymentMethod, requireStudentNumber) =>
          [Recipient.TO_FRIEND, Recipient.TO_SELF].includes(recipient)
          && !!requireSubscriberInfo
          && (paymentMethod === PaymentMethods.INVOICING_CALL || paymentMethod === PaymentMethods.MOBILEPAY)
          && !requireStudentNumber,
        then: subscriberDetails(true, true, false),
      })
      .when(['recipient', 'requireSubscriberInfo', 'requireStudentNumber'], {
        is: (recipient, requireSubscriberInfo, requireStudentNumber) =>
          [Recipient.TO_FRIEND, Recipient.TO_SELF].includes(recipient)
          && !!requireSubscriberInfo
          && requireStudentNumber,
        then: subscriberDetails(true, false, true),
      })
      .when(['recipient', 'requireSubscriberInfo', 'requireStudentNumber'], {
        is: (recipient, requireSubscriberInfo, requireStudentNumber) =>
          [Recipient.TO_FRIEND, Recipient.TO_SELF].includes(recipient)
          && !!requireSubscriberInfo
          && !requireStudentNumber,
        then: subscriberDetails(true, false, false),
      }),
    recipientDetails: yup.object().when('recipient', {
      is: (val) => val === Recipient.TO_FRIEND,
      then: recipientDetails(),
    }),
    companyDetails: yup.object().when('recipient', {
      is: (val) => val === Recipient.TO_COMPANY,
      then: companyDetails(),
    }),
    terms: yup.boolean().oneOf([true], ValidationError.TERMS_NOT_ACCEPTED),
    emailMarketingConsent: yup.boolean(),
  })
  .required();

export const TALLENNUS_PAGE_OFFER_FORM_SCHEMA = yup.object().shape(
  {
    tyrkyteId: yup.string().when(['offeringId', 'barcode'], {
      is: (offeringId, barcode) => !offeringId && !barcode,
      then: yup
        .string()
        .required(ValidationError.AT_LEAST_ONE_FIELD_IS_REQUIRED),
    }),
    offeringId: yup.string().when(['tyrkyteId', 'barcode'], {
      is: (barcode, tyrkyteId) => !barcode && !tyrkyteId,
      then: yup
        .string()
        .required(ValidationError.AT_LEAST_ONE_FIELD_IS_REQUIRED),
    }),
    barcode: yup.string().when(['offeringId', 'tyrkyteId'], {
      is: (offeringId, tyrkyteId) => !offeringId && !tyrkyteId,
      then: yup
        .string()
        .required(ValidationError.AT_LEAST_ONE_FIELD_IS_REQUIRED),
    }),
  },
  [
    ['offeringId', 'barcode'],
    ['tyrkyteId', 'barcode'],
    ['offeringId', 'tyrkyteId'],
  ],
);

export const TALLENNUS_PAGE_ORDER_FORM_SCHEMA = yup
  .object()
  .shape({
    startingDate: yup.string().test({
      test(val, ctx) {
        if (!val) return true;
        const date = new Date(Date.parse(val));
        if (!validators.isValidDate(date))
          return ctx.createError({
            message: ValidationError.INVALID_STARTING_DATE,
          });
        const dateWithoutHoursAndMinutes = new Date(
          date.toISOString().split('T')[0],
        );
        const dateTodayWithoutHoursAndMinutes = new Date(
          new Date().toISOString().split('T')[0],
        );
        if (dateTodayWithoutHoursAndMinutes > dateWithoutHoursAndMinutes)
          return ctx.createError({
            message: ValidationError.STARTING_DATE_IN_THE_PAST,
          });
        return true;
      },
    }),
    subscriberDetails: yup.object().when('recipient', {
      is: (val) => val === Recipient.TO_SELF,
      then: subscriberDetails(false),
    }),
    recipientDetails: yup.object().when('recipient', {
      is: (val) => val === Recipient.TO_FRIEND,
      then: recipientDetails(),
    }),
    companyDetails: yup.object().when('recipient', {
      is: (val) => val === Recipient.TO_COMPANY,
      then: companyDetails(),
    }),
  })
  .required();
