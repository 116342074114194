/*
 * Collect util/helper functions here.
 */
import React from 'react';
import { HIDE_HEADER_AND_FOOTER_TYPES } from '../actions/actionTypes';
import { ErrorCodeMap, OrderType, PaymentMethods } from './consts';
import t from '../locales/fi';

const isProd = process.env.NODE_ENV === 'production';

const formatPrice = (price, discountPercentage, numOfPayments) => {
  let offerPrice;
  if (typeof price === 'string') {
    offerPrice = parseFloat(price.replace(',', '.'));
  } else {
    offerPrice = price;
  }
  if (offerPrice === 0) {
    return '0 €';
  }

  /* Offer price should be lowered by 'discountPercentage' (if applicable).
  This price is only shown on site, not forwarded to the API */
  if (discountPercentage && discountPercentage > 0) {
    offerPrice *= (100 - discountPercentage) / 100;
  }

  /* Divide by number of payments, if applicable */
  if (numOfPayments && numOfPayments > 0) {
    offerPrice /= numOfPayments;
  }

  if (Number.isInteger(offerPrice)) {
    return `${offerPrice.toFixed(0)} €`;
  }
  return `${offerPrice.toFixed(2).replace('.', ',')} €`;
};

const FormatStartDate = (offerStartDate, magazineStartDate) => {
  const startDate = offerStartDate || magazineStartDate;
  if (!startDate) return null;
  const startDateStr = (
    <>
      ,
      <br />
      {`alkaen ${new Date(Date.parse(startDate)).toLocaleDateString('fi-FI')}`}
    </>
  );

  return startDateStr;
};

const showPriceByOrderType = (price, discountPercentage, orderType) => {
  switch (orderType) {
    case 'L':
      return null;
    default:
      return formatPrice(price, discountPercentage);
  }
};

/**
 * Get starting date element visibility by order type. If orderType = I (free), return null.
 * @return {null|String}
 */
const startingDateVisibilityByOrderType = (
  startBeginning,
  orderStarts,
  orderType,
) => {
  switch (orderType) {
    case 'I':
      return null;
    default:
      return FormatStartDate(startBeginning, orderStarts);
  }
};

/**
 * Resolve if its ok to allow read access with token (to external sites).
 *
 * @param subscription
 * @return {*|boolean}
 */
const resolveIsTokenRedirectAllowed = (subscription) =>
  (subscription.otavamediaAccount
    && subscription.otavamediaAccount !== 'failed')
  || subscription.subscriptionSuccessful;

/**
 * Resolve redirect url for token authentication (on external sites).
 *
 * @param articleURL
 * @param readToken
 * @param isPaywall
 * @return {string|null}
 */
const resolveTokenRedirectUrl = (articleURL, readToken, isPaywall) => {
  // If no token or no article url, return null
  if (readToken === 'failed' || !articleURL) return null;

  let domain = articleURL
    .replace('http://', '')
    .replace('https://', '')
    .split(/[/?#]/)[0];
  // TODO: Remove after redirect is added to tm-front
  if (domain === 'tmbeta.vik.fi') {
    domain = 'tmbeta-api.vik.fi';
  }
  if (domain === 'tekniikanmaailma.fi') {
    domain = 'vanha.tekniikanmaailma.fi';
  }
  if (domain === 'metsastysjakalastus.fi') {
    domain = 'wp.metsastysjakalastus.fi';
  }

  if (isPaywall) {
    /*
     * https://jira.eficode.fi/browse/OTAVAMSSD-176:
     *
     * "orderstatus" url parameter is wanted in here for "purchase tracking".
     * It also has to be successful if order was sent to API2 (successfully or error list).
     * We only redirect if order is sent to API2 (successfully or error list).
     * -> orderstatus is always successful? What? Why!?
     */
    return `https://${domain}/asauth/remote-auth?astoken=${readToken}&orderstatus=successful`;
    // Digi-only campaign page orders
  }
  return `https://${domain}/asauth/remote-auth?astoken=${readToken}`;
};

const Recipient = {
  TO_COMPANY: 'yritykselle',
  TO_FRIEND: 'kaverille',
  TO_SELF: 'itselle',
};

/**
 * Wether or not country should display order abroad text
 * @param {string} country
 * @returns {boolean}
 */
const countryShouldDisplayOrderAbroadText = (country) =>
  !['FI', '', undefined].includes(country);

const isDigiOrder = (productLines) => {
  if (!Array.isArray(productLines)) return false;
  return !!productLines.find((p) => p.productNumber >= 70000);
};

const findFirstDigiPaperCode = (productLines) =>
  productLines.find((p) => p.productNumber >= 70000)?.paperCode || null;

const resolveInvoicingChangeEmail = (subscription) => {
  const payer = subscription?.payer;
  const subscriber = subscription?.subscriber;
  if (payer && payer.emailAddress) return payer.emailAddress;
  if (subscriber && subscriber.emailAddress) return subscriber.emailAddress;
  return null;
};

// Todo: move to a lang file if one exists when you are reading this
const orderAbroadText
  = 'Ulkomaille lähetettävien painettujen lehtien tilaushintoihin lisätään postimaksu.';

const setCompanyDetails = (customer, companyDetails) => {
  if (customer.tyrkyteId && customer.tyrkyteId !== 'dummy') {
    return {
      payerMasterasiakasId: `${companyDetails.payerMasterAsiakasId
        ? companyDetails.payerMasterAsiakasId
        : ''
      }`,
      payerLastName: `${customer.customer.lastName
        ? customer.customer.lastName
        : companyDetails.payerLastName
      }`,
      payerCompanyId: `${customer.customer.companyId
        ? customer.customer.companyId
        : companyDetails.companyId
      }`,
      payerStreetAddress: `${customer.customer.streetAddress
        ? customer.customer.streetAddress
        : companyDetails.payerStreetAddress
      }`,
      payerCountry: `${customer.customer.country
        ? customer.customer.country
        : companyDetails.payerCountry
      }`,
      payerZipCode: `${customer.customer.zipCode
        ? customer.customer.zipCode
        : companyDetails.payerZipCode
      }`,
      payerCity: `${customer.customer.city
        ? customer.customer.city
        : companyDetails.payerCity
      }`,
      payerCoName1: `${customer.customer.coName1
        ? customer.customer.coName1
        : companyDetails.payerCoName1
      }`,
      payerEmail: companyDetails.payerEmail,
    };
  }
  return companyDetails;
};

const setGifts = (offer, selectedGift) => {
  if (selectedGift !== null && offer.magazines[0].optionalGifts === '1') {
    return [selectedGift];
  }
  return offer.gifts.map((gift) => gift.id);
};

const setSubscriberDetails = (customer, subscriberDetails) => {
  if (customer.tyrkyteId && customer.tyrkyteId !== 'dummy') {
    return {
      payerMasterasiakasId: `${subscriberDetails.payerMasterAsiakasId
        ? subscriberDetails.payerMasterAsiakasId
        : ''
      }`,
      payerFirstName: `${customer.customer.firstName
        ? customer.customer.firstName
        : subscriberDetails.payerFirstName
      }`,
      payerLastName: `${customer.customer.lastName
        ? customer.customer.lastName
        : subscriberDetails.payerLastName
      }`,
      payerStreetAddress: `${customer.customer.streetAddress
        ? customer.customer.streetAddress
        : subscriberDetails.payerStreetAddress
      }`,
      payerCountry: `${customer.customer.country
        ? customer.customer.country
        : subscriberDetails.payerCountry
      }`,
      payerZipCode: `${customer.customer.zipCode
        ? customer.customer.zipCode
        : subscriberDetails.payerZipCode
      }`,
      payerCity: `${customer.customer.city
        ? customer.customer.city
        : subscriberDetails.payerCity
      }`,
      payerPhoneNumber: subscriberDetails.payerPhoneNumber,
      payerEmail: subscriberDetails.payerEmail,
    };
  }
  return subscriberDetails;
};

const hardcodedTestPaymentMethodsByOffering = {
  9242: {
    pankki: true,
    lasku: true,
    kortti: true,
  },
  9271: {
    pankki: true,
  },
  9301: {
    pankki: true,
    lasku: true,
  },
  9302: {
    pankki: true,
    kortti: true,
  },
  198641: {
    pankki: true,
  },
  185831: {
    pankki: true,
  },
  185861: {
    pankki: true,
  },
  185891: {
    pankki: true,
  },
  198251: {
    pankki: true,
  },
  198281: {
    pankki: true,
  },
  196661: {
    pankki: true,
  },
  202601: {
    pankki: true,
  },
  193991: {
    pankki: true,
  },
  207312: {
    pankki: true,
  },
  207371: {
    pankki: true,
  },
  207372: {
    pankki: true,
  },
  207373: {
    pankki: true,
  },
  207374: {
    pankki: true,
  },
  207375: {
    pankki: true,
  },
  207401: {
    pankki: true,
  },
  207402: {
    pankki: true,
  },
  207403: {
    pankki: true,
  },
  207404: {
    pankki: true,
  },
  204104: {
    pankki: true,
  },
  194051: {
    pankki: true,
  },
  193603: {
    pankki: true,
  },
  204077: {
    pankki: true,
  },
  193516: {
    pankki: true,
  },
  198792: {
    pankki: true,
  },
  198791: {
    pankki: true,
  },
  198941: {
    pankki: true,
  },
  199091: {
    pankki: true,
  },
  199121: {
    pankki: true,
  },
  199151: {
    pankki: true,
  },
  199331: {
    pankki: true,
  },
  199242: {
    pankki: true,
  },
  199243: {
    pankki: true,
  },
  199391: {
    pankki: true,
  },
  199181: {
    pankki: true,
  },
  199421: {
    pankki: true,
  },
};

/**
 * OTVMSUP-104
 * Functionality to check if offer contains only single brand to send package type to Datalayer
 */
const checkIfSingleBrand = (data) => {
  const packageBanner = data?.banners?.packageBanner;
  const magazine = data?.magazines[0]?.packageId;
  if (packageBanner) {
    if (magazine) {
      return magazine.split(' ').shift();
    }
  }
  return false;
};

const parseUrlParams = (urlSearchParams) => {
  const parsedParams = {};
  urlSearchParams.forEach((value, key) => {
    parsedParams[key] = value;
  });
  return parsedParams;
};

const Layout = {
  LIST: 'list',
  BRAND: 'brand',
  CAROUSEL: 'carousel',
  DROPDOWN: 'dropdown',
};

/**
 * Resolve magazine layout from offer.
 *
 * @param offer
 * @returns {string}
 */
const resolveMagazineLayout = (offer) => {
  if (offer.layout === 'singleCol' && offer.magazines.length !== 0) {
    return Layout.LIST;
  }
  if (offer.layout === 'twoCols' && offer.brands.length > 0) {
    return Layout.BRAND;
  }
  if (offer.layout === 'karuselli') return Layout.CAROUSEL;
  // Default to carousel
  return 'carousel';
};

const subscribeButtonDisabled = (
  offer,
  selectedMagazineId,
  selectedGiftId,
  paymentMethod,
) => {
  const { paymentMethodCreditCard, paymentMethodInvoice, paymentMethodBank }
    = offer.magazines[0];
  const paymentRequired
    = paymentMethodCreditCard === '1'
    || paymentMethodInvoice === '1'
    || paymentMethodBank === '1';
  const magazineNotSelected = !selectedMagazineId;
  const paymentMethodRequiredAndNotSelected
    = paymentRequired && (selectedMagazineId || selectedGiftId) && !paymentMethod;
  const giftOptionalAndNotSelected
    = offer.magazines[0].optionalGifts === '1'
    && offer.gifts.length > 0
    && !selectedGiftId;
  return (
    magazineNotSelected
    || paymentMethodRequiredAndNotSelected
    || giftOptionalAndNotSelected
  );
};

const POPUP_TYPE = {
  MAGAZINE: 'magazine',
  MAGAZINE_IMAGE: 'magazine_image',
  GIFT: 'gift',
  GIFT_IMAGE: 'gift_image',
  TERMS: 'terms', // Not used
};

/**
 * Get background color based on layout and offer
 * In case layout is brand, background should be white and only carousel should have color
 * If there are only 1 brand there should color of this brand
 * If more than 1 brand, background should be default color for multiple magazines
 * found in options
 * @param layout
 * @param offer
 * @returns {*|string}
 */
const getBackgroundColor = (layout, offer) => {
  if (layout === Layout.BRAND) {
    return '#ffffff';
  }
  if (offer.brandsOnOffering.length === 1 && offer.magazines[0]?.themeColor) {
    return offer.magazines[0].themeColor;
  }
  return offer.options.campaignpages.colors.multiple;
};

const shouldShowHeaderAndFooter = (state) =>
  !Object.values(HIDE_HEADER_AND_FOOTER_TYPES).includes(state);

const salesTypeStringBySubscriptionKind = (kind) => {
  switch (kind) {
    case OrderType.CONTINUOUS:
      return 'Jatkuva tilaus';
    case OrderType.FIXED:
      return 'Määräaikainen tilaus';
    case OrderType.SINGLE_ISSUE:
      return 'Irtonumerotilaus';
    case OrderType.CHECK_ISSUE:
      return 'Tarkistuskappale';
    case OrderType.FREE_ISSUE:
      return 'Vapaakappale';
    case OrderType.SAMPLE:
      return 'Näytenumero';
    default:
      return '';
  }
};

const selectPaymentMethodText = (paymentMethod = null) => {
  if (paymentMethod === PaymentMethods.INVOICING_E_IDENT) {
    return t.INVOICE_PAYMENT_BANK;
  }
  if (paymentMethod === PaymentMethods.INVOICING_CALL) {
    return t.INVOICE_PAYMENT_CALL;
  }
  if (paymentMethod === PaymentMethods.MOBILEPAY) {
    return t.MOBILEPAY_PAYMENT;
  }
  if (paymentMethod === PaymentMethods.CARD) {
    return t.CARD_PAYMENT;
  }
  if (paymentMethod === PaymentMethods.INVOICING_PAYTRAIL) {
    return t.BANK_PAYMENT;
  }
  return '';
};

const getErrorText = (errorCode) => {
  const errorText = ErrorCodeMap[errorCode];
  if (!errorText) return t.DEFAULT_ERROR;
  if (
    errorText == ErrorCodeMap.OFFER_INVALID_ALL_OFFERS_HAVE_ACTIVE_SUBSCRIPTION
  ) {
    return t.DEFAULT_ERROR_HTML;
  }
  return errorText;

};

const getCustomerFromOrder = (order) => ({
  firstName: order.payerFirstName || '',
  lastName: order.payerLastName || '',
  companyId: order.payerCompanyId || '',
  streetAddress: order.payerStreetAddress || '',
  country: order.payerCountry || '',
  zipCode: order.payerZipCode || '',
  city: order.payerCity || '',
  phoneNumber: order.payerPhoneNumber || '',
  birthDay: order.payerBirthday || '',
  coName1: order.payerCoName1 || '',
  email: order.payerEmail || '',
});

/**
 * Checks magazine for allowed payment methods.
 * @param magazine
 * @returns {{nothingAllowed: boolean, card: boolean, mobilepay: boolean, invoice: boolean, paytrail: boolean}}
 */
const parsePaymentMethodsFromMagazine = (magazine) => {
  const cardPaymentAllowed = magazine.paymentMethodCreditCard === '1';
  const invoicePaymentAllowed = magazine.paymentMethodInvoice === '1';
  const bankPaymentAllowed = magazine.paymentMethodBank === '1';
  return {
    nothingAllowed:
      !cardPaymentAllowed && !invoicePaymentAllowed && !bankPaymentAllowed,
    [PaymentMethods.CARD]: cardPaymentAllowed,
    [PaymentMethods.MOBILEPAY]: cardPaymentAllowed, // Mobilepay is allowed whenever card is allowed
    [PaymentMethods.INVOICE]: invoicePaymentAllowed,
    [PaymentMethods.INVOICING_PAYTRAIL]: bankPaymentAllowed,
  };
};

const hardcodedSampleOfferingConfig = {
  HARDCODED_SALES_NUMBERS: ['1095'],
  SAMPLE_OFFERING_LINK: isProd
    ? 'https://lunastukset.otavamedia.fi/etulehti'
    : 'http://tilaus-test.anna.fi/9751',
};

const hardcodedStudentOffersTestEnv = ['10021'];
const hardcodedStudentOffersProdEnv = ['256841'];

const shouldDisplayStudentNumberInput = (offerId) => {
  const studentOfferArray = isProd ? hardcodedStudentOffersProdEnv : hardcodedStudentOffersTestEnv;
  return studentOfferArray.includes(offerId);
};

/**
 * If consents object has keys 1 and 8 as true, user has given consent
 * @returns {boolean}
 */
const parseConsentGiven = () => {
  try {
    const { purpose: { consents } = {} } = window.tcstring_decoded || {};
    return consents ? (consents[1] && consents[8]) : false;
  } catch (error) {
    console.error('Error parsing consents', error);
    return false;
  }
};

const formatPhoneNumber = (phoneNumber) => {
  // Remove all non numeric characters except for the leading +
  let parsed = phoneNumber.replace(/[^+\d]/g, '');

  if (parsed.length === 0) {
    return '';
  }

  // Starts with +358, is correct format
  if (parsed.startsWith('+358')) {
    return parsed;
  }

  // Check if the phone number starts with 0
  // Replace leading 0 with +358
  if (parsed.startsWith('0')) {
    parsed = `+358${parsed.substring(1)}`;
  }
  // Check if the phone number starts with 358 without +
  else if (parsed.startsWith('358')) {
    parsed = `+${parsed}`;
  }
  // Otherwise add +358 as the country code
  else {
    parsed = `+358${parsed}`;
  }

  return parsed;
};

export {
  isProd,
  selectPaymentMethodText,
  checkIfSingleBrand,
  countryShouldDisplayOrderAbroadText,
  findFirstDigiPaperCode,
  formatPrice,
  FormatStartDate,
  getCustomerFromOrder,
  getErrorText,
  isDigiOrder,
  Layout,
  orderAbroadText,
  parseUrlParams,
  POPUP_TYPE,
  Recipient,
  resolveInvoicingChangeEmail,
  resolveIsTokenRedirectAllowed,
  resolveMagazineLayout,
  resolveTokenRedirectUrl,
  parsePaymentMethodsFromMagazine,
  salesTypeStringBySubscriptionKind,
  setCompanyDetails,
  setGifts,
  setSubscriberDetails,
  showPriceByOrderType,
  startingDateVisibilityByOrderType,
  subscribeButtonDisabled,
  getBackgroundColor,
  hardcodedTestPaymentMethodsByOffering,
  shouldShowHeaderAndFooter,
  hardcodedSampleOfferingConfig,
  shouldDisplayStudentNumberInput,
  parseConsentGiven,
  formatPhoneNumber,
};
